<script setup>

</script>
<template>
    <div class="bg-cool-gray-light mb-[96px] lg:mb-[112px] pt-16 text-center 2xl:text-left">
        <Container noPaddings>
            <div class="grid grid-cols-1 lg:grid-cols-2 ">
                <div>
                    <p class="mb-6 lg:mb-12 text-azure-dark text-4xl">
                        Szybki kontakt
                    </p>
                    <p class="mb-6 lg:mb-12 text-coolGray-700 text-xl">
                        Jesteśmy dostępni pod dedykowanym numerem infolinii:
                    </p>
                    <ButtonCall customContent :show-working-hours="false" :azure="true">
                    <p class="text-4xl md:text-[64px] md:leading-[64px] font-semibold text-azure">
                        <span class="text-2xl md:text-4xl font-normal">
                            +48
                        </span>
                        729 087 800
                    </p>
                    </ButtonCall>
                </div>
                <div class="">
                    <img class="w-full h-[300px] object-contain mt-16 2xl:mt-0" src="@/assets/images/statics/vehis-calling.png" alt="" />
                </div>
            </div>
        </Container>
    </div>
</template>